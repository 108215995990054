import AOS from 'aos'
import 'aos/dist/aos.css';


jQuery(document).ready(function($) {

	//aos settings global
	AOS.init({
		duration: 1200, // values from 0 to 3000, with step 50ms
		easing: 'ease',
		offset: 150,
		// disable: 'mobile',
		disable: function() {
			var maxWidth = 1160;
			return window.innerWidth < maxWidth;
		}
	});

	
	//menu
	$('body').on('click','.mobileMenu',function(){
		$('#page').toggleClass('mobileOpen');
		$('.menu .content').toggleClass('open');
		$('.menuContainer').toggleClass('active');
		$('.menuContainer .menu').toggleClass('show');

		$(this).toggleClass('open');
	});


	//close menu for desktop if resize is bigger than breakpoint
	$(window).on('resize', function(){
		var win = $(this); //this = window

		if (win.width() > 1160) {
			$('#page').removeClass('mobileOpen');
			$('.menu .content').removeClass('open');
			$('.menuContainer').removeClass('active');
			$('.menuContainer .menu').removeClass('show');

			$('.mobileMenu').removeClass('open');
		}
	});




	//menu detection to show sticky nav classes
	$(document).scroll(function() { 
		if($(window).scrollTop() !== 0) {
		  $("header").addClass('scrolled');
		}

		if($(window).scrollTop() === 0) {
			$("header").removeClass('scrolled');
		}
	});

	if(window.scrollY != 0){
		$("header").addClass('scrolled');
	}





	//header code form bootstrap mobile accordion

	var windowsize = $(window).width();

	if (windowsize < 992){
		var mobileDataId = $('#bicCodesForm .accordion-button').attr('data-bs-target-mobile');
		$('#bicCodesForm .accordion-button').attr('data-bs-target', mobileDataId);
		$('#bicCodesForm .accordion-button').addClass('collapsed');
		$('#bicCodesForm .accordion-collapse').removeClass('show');
		$('#bicCodesForm .accordion-button').attr('aria-expanded', 'true');
	}

	$(window).resize(function() {
		windowsize = $(window).width();

		if (windowsize < 992){
			var mobileDataId = $('#bicCodesForm .accordion-button').attr('data-bs-target-mobile');
			var DataId = $('#bicCodesForm .accordion-button').attr('data-bs-target');
			$('#bicCodesForm .accordion-button').addClass('collapsed');
			$('#bicCodesForm .accordion-collapse').removeClass('show');
			$('#bicCodesForm .accordion-button').attr('aria-expanded', 'true');

			if(mobileDataId != DataId){
				$('#bicCodesForm .accordion-button').attr('data-bs-target', mobileDataId);
				$('#bicCodesForm .accordion-button').attr('aria-expanded', 'true');
			}
		}

		if (windowsize > 991){
			var mobileDataId = $('#bicCodesForm .accordion-button').attr('data-bs-target-mobile');
			var DataId = $('#bicCodesForm .accordion-button').attr('data-bs-target');
			$('#bicCodesForm .accordion-collapse').addClass('show');
			$('#bicCodesForm .accordion-button').removeClass('collapsed');
			$('#bicCodesForm .accordion-button').attr('aria-expanded', 'false');

			if(mobileDataId == DataId){
				$('#bicCodesForm .accordion-button').attr('data-bs-target', DataId+'None');
			}
		}
	});
	

	$('body').on('click', '#started .dropdown-item', function(event){
		event.preventDefault();
		
		var title = $(this).attr('data-title');
		var link = $(this).attr('data-link');
		
		$('#started button').html(title)
		$('#getStarted').attr("href", link)
	});

});